import { useState } from 'react';
import { useApiService } from '@api/services';
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { ChartInterval } from '@components/Dashboard/ChartInterval';
import { Loader } from '@components/shared/Loader';
import { TimeSeriesReviews } from '@api/types';
import { IncidentReviewChartProp } from '@typeDef/Dashboard';
import { formatTime } from '@utils/date';
import { ChartHandler } from '@components/Dashboard/ChartHandler';
import { useUser } from '@context/UserProvider';

ChartJS.register(...registerables);

const ReviewChart = ({ start, end }: IncidentReviewChartProp) => {
  const [interval, setInterval] = useState<string>('day');
  const intervals = ['day', 'week', 'month'];
  const { theme } = useUser();

  const { useReviews } = useApiService();

  const {
    data: reviews,
    error: reviewsError,
    isLoading: reviewIsLoading
  } = useReviews({
    start,
    end,
    interval
  });

  const options = {
    resizeDelay: 150,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: { color: `${theme === 'dark' && 'white'}` },
        grid: { display: false },
        border: { display: false }
      },
      bar: {
        ticks: { color: `${theme === 'dark' && 'white'}` },
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        border: { display: false },
        grid: {
          display: false
        }
      },
      line: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        border: { display: false },
        grid: {
          drawOnChartArea: false,
          display: false
        },
        min: 0,
        suggestedMax: 100,
        ticks: {
          color: `${theme === 'dark' && 'white'}`,
          callback: (value: string | number) => `${value}%`
        }
      }
    },
    plugins: {
      legend: {
        onHover: (event: any) => {
          event.native.target.style.cursor = 'pointer';
        },
        onLeave: (event: any) => {
          event.native.target.style.cursor = 'default';
        },
        labels: {
          useBorderRadius: true,
          borderRadius: 4,
          boxHeight: 10,
          color: `${theme === 'dark' && 'white'}`
        }
      },
      tooltip: {
        mode: 'index' as const,
        callbacks: {
          label: function (context: any) {
            const { label } = context.dataset;
            const { formattedValue, raw } = context;
            if (label === 'Enforcement rate') {
              return `${label} ${raw.toFixed(2)}%`;
            }
            return `${label} ${formattedValue}`;
          }
        }
      }
    }
  };

  const getEnforcementRate = (reviews?: TimeSeriesReviews[]) => {
    if (reviews) {
      return reviews.map(
        (x: TimeSeriesReviews) =>
          (x.enforcements / Math.max(x.reviews, 1)) * 100
      );
    }
  };

  const chartData = {
    labels: reviews?.series?.map((x: TimeSeriesReviews) =>
      formatTime(x.time, reviews.interval)
    ),
    datasets: [
      {
        type: 'line' as const,
        label: 'Enforcement rate',
        borderColor: 'rgb(0 126 252)',
        backgroundColor: 'rgb(0 126 252)',
        data: getEnforcementRate(reviews?.series),
        yAxisID: 'line'
      },
      {
        data: reviews?.series?.map((x: TimeSeriesReviews) => x.reviews),
        label: 'Reviews',
        yAxisID: 'bar',
        backgroundColor: 'rgb(129 133 248)',
        hoverBackgroundColor: 'rgb(99 102 241)',
        borderRadius: 10
      }
    ]
  };

  return (
    <div className="incidentReviewChartContainer border border-border w-full relative bg-custom-bg">
      <ChartInterval
        title="Reviews"
        intervals={intervals}
        interval={interval}
        setInterval={setInterval}
      />
      <ChartHandler error={reviewsError}>
        <div className="chartContainer">
          {reviewIsLoading ? (
            <Loader />
          ) : (
            <div className="w-full relative">
              <Chart
                data-testid="chart"
                type="bar"
                options={options}
                data={chartData}
                className="max-w-full h-[252px] bg-custom-bg"
              />
            </div>
          )}
        </div>
      </ChartHandler>
    </div>
  );
};

export { ReviewChart };
