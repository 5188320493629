/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type HasComplexTypes = {
    op: HasComplexTypes.op;
    complexTypes: Array<string>;
};
export namespace HasComplexTypes {
    export enum op {
        HAS_COMPLEX_TYPES = 'has_complex_types',
    }
}

