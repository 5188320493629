import { useApiClient } from '@hooks/useApiClient';
import { Queue, QueueStatsResponse, QueueNextItemResponse } from '@api/types';
import { useQuery } from '@tanstack/react-query';
import { useUser } from '@context/UserProvider';
import { assignmentPayload } from '@typeDef/Review';

const useQueuesApi = () => {
  const apiClient = useApiClient();
  const { platform } = useUser();

  const useQueues = ({ platformId }: { platformId?: string }) => {
    const id = platformId ? platformId : platform?.id;
    return useQuery({
      queryKey: ['queues', id],
      queryFn: async () => {
        const { data } = await apiClient.get<Queue[]>(
          `/platforms/${id}/queues`
        );
        return data;
      },
      enabled: !!id
    });
  };

  const useNextCase = ({ queueId }: { queueId?: string }) =>
    useQuery({
      queryKey: ['queueId', platform?.id, queueId],
      queryFn: async () => {
        const { data } = await apiClient.get<QueueNextItemResponse>(
          `/review/platforms/${platform?.id}/queues/${queueId}/next`
        );
        return data;
      },
      enabled: !!platform?.id && !!queueId
    });

  const useQueuesCount = () => {
    const refetchInterval =
      import.meta.env.MODE === 'development' ? 300000 : 5000;
    return useQuery({
      queryKey: ['queueCount', platform?.id],
      queryFn: async () => {
        const { data } = await apiClient.get<QueueStatsResponse>(
          `review/platforms/${platform?.id}/queues/stats`,
          {
            params: {
              tz: platform?.timezone
            }
          }
        );
        return data;
      },
      refetchInterval,
      enabled: !!platform?.id,
      placeholderData: (previousData) => previousData
    });
  };

  const useQueueDescription = ({
    queueId,
    platformId
  }: {
    queueId?: string;
    platformId?: string;
  }) => {
    const id = platformId ? platformId : platform?.id;
    return useQuery({
      queryKey: ['queueDescription', id, queueId],
      queryFn: async () => {
        const { data } = await apiClient.get<Queue>(
          `/platforms/${id}/queues/${queueId}`
        );
        return data;
      },
      enabled: !!queueId && !!id && queueId !== 'noqueue'
    });
  };

  const useAssignCase = ({ complexType, contentId }: assignmentPayload) =>
    useQuery({
      queryKey: ['assignEvent', platform?.id, complexType, contentId],
      queryFn: async () => {
        const { data } = await apiClient.post(
          `/review/platforms/${platform?.id}/content-cases/assignment`,
          { complexType, contentId }
        );
        return data;
      },
      enabled: !!platform?.id && !!complexType && !!contentId
    });

  return {
    useQueues,
    useQueueDescription,
    useNextCase,
    useQueuesCount,
    useAssignCase
  };
};

export { useQueuesApi };
