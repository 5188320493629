import { FormEvent, ChangeEvent, useState, useEffect } from 'react';
import { Input } from '@components/shared/Input';
import { Button } from '@components/shared/Buttons';
import { PolicyRuleType } from '@typeDef/Policy';
import { Dropdown } from '@components/shared/Dropdown';
import { useUser } from '@context/UserProvider';

type Props = {
  onEdit: (rule: PolicyRuleType) => void;
  ruleSelected: PolicyRuleType | null;
};

const EditRuleForm = ({ ruleSelected, onEdit }: Props) => {
  const [error, setError] = useState('');
  const [inputLower, setInputLower] = useState(
    ruleSelected?.lower_threshold || 0
  );
  const [inputUpper, setInputUpper] = useState(
    ruleSelected?.upper_threshold || 0
  );
  const [inputRestriction, setInputRestriction] = useState(
    ruleSelected?.content_type
  );
  const { domainObjects } = useUser();

  useEffect(() => {
    setError('');
  }, [inputLower, inputUpper, inputRestriction]);

  if (!ruleSelected) return null;

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    const contentType =
      inputRestriction === 'none' ? undefined : inputRestriction;
    e.preventDefault();
    if (error) return;
    onEdit({
      lower_threshold: inputLower,
      upper_threshold: inputUpper,
      label: ruleSelected.label,
      field: ruleSelected.field,
      strategy: ruleSelected.strategy,
      content_type: contentType,
      original: ruleSelected
    });
  };

  return (
    <div className="p-4 max-h-[50%]">
      <h2>Edit rule</h2>
      <form onSubmit={onSubmit} className="flex flex-col">
        <div className="flex justify-between mb-3 gap-4 mt-4">
          <Input
            htmlFor="low"
            label="Queue content threshold %"
            type="number"
            rest={{
              min: 0,
              max: inputUpper,
              step: 1,
              defaultValue: ruleSelected?.lower_threshold,
              onChange: (e: ChangeEvent<HTMLInputElement>) =>
                setInputLower(parseFloat(e.target.value))
            }}
            placeholder="Enter score from 0 to 1"
            required={true}
          />
          <Input
            htmlFor="high"
            label="Automatic enforcement threshold %"
            type="number"
            rest={{
              min: inputLower,
              max: 100,
              step: 1,
              defaultValue: ruleSelected.upper_threshold,
              onChange: (e: ChangeEvent<HTMLInputElement>) =>
                setInputUpper(parseFloat(e.target.value))
            }}
            placeholder="Enter score from 0 to 1"
            required={true}
          />
        </div>
        {domainObjects && (
          <>
            <div className="text-slate-500 text-sm mb-2">Resticted to</div>
            <Dropdown
              onAction={setInputRestriction}
              title={inputRestriction ?? 'none'}
              list={[...domainObjects.map(({ type }) => type), 'none']}
              mainClass="w-[calc(50%-8px)] p-0 bg-custom-bg mb-3"
              buttonStyle="rounded-md border border-neutral-200 p-2 hover:bg-transparent hover:border-neutral-200"
              listStyle="absolute z-40 border bg-custom-bg border-neutral-200 rounded-sm overflow-auto"
              itemStyle="hover:bg-transparent hover:text-cta px-4"
            />
          </>
        )}
        <div className="flex">
          <div className="text-red flex">{error}</div>
          <div className="flex ml-auto">
            <Button
              onClick={() => null}
              style="button w-36"
              hiddenTitle="Edit-rule-submit"
              disabled={!!error}
            >
              Edit rule
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export { EditRuleForm };
