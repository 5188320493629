/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type HasBeenAutomated = {
    op: HasBeenAutomated.op;
};
export namespace HasBeenAutomated {
    export enum op {
        HAS_BEEN_AUTOMATED = 'has_been_automated',
    }
}

