import { FaUndoAlt } from 'react-icons/fa';
import { RxCross1 } from 'react-icons/rx';

const KeywordChip = ({
  onClick,
  keyword,
  style,
  selected = false
}: {
  onClick?: (val: string) => void;
  keyword: string;
  style?: string;
  selected?: boolean;
}) => (
  <div
    key={`${keyword}--list`}
    onClick={() => onClick?.(keyword)}
    className={`px-2 flex gap-2 flex-row justify-between roundedContainer text-neutral-800 items-center ${onClick ? 'cursor-pointer' : ''} ${selected ? 'bg-secondary-200 hover:bg-secondary-200' : 'bg-primary-200 hover:bg-secondary-200'} font-medium ${style}`}
  >
    <div className="min-w-fit overflow-hidden line-clamp-1">{keyword}</div>
    {onClick &&
      (selected ? (
        <FaUndoAlt size={12} className="stroke-1" />
      ) : (
        <RxCross1 size={12} className="stroke-1" />
      ))}
  </div>
);

export { KeywordChip };
