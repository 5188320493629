import { SummaryMetrics } from '@components/Dashboard/SummaryMetrics';
import { EventChart } from '@components/Dashboard/EventChart';
import { ReviewChart } from '@components/Dashboard/ReviewChart';
import { useDate } from '@context/DateProvider';
import { DailySummary } from '@components/Dashboard/DailySummary';

const DashboardOverview = () => {
  const { startDate, endDate } = useDate();

  return (
    <div>
      <SummaryMetrics durationStart={startDate} durationEnd={endDate} />
      <div className="dashboardCharts flex-col mlg:flex-row gap-[20px] mlg:gap-0 w-full">
        <EventChart start={startDate} end={endDate} />
        <ReviewChart start={startDate} end={endDate} />
      </div>
      <DailySummary durationStart={startDate} durationEnd={endDate} />
    </div>
  );
};

export default DashboardOverview;
