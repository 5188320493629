/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type HasOriginPrefix = {
    op: HasOriginPrefix.op;
    prefix: string;
};
export namespace HasOriginPrefix {
    export enum op {
        HAS_ORIGIN_PREFIX = 'has_origin_prefix',
    }
}

