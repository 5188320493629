/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type HasLevel = {
    op: HasLevel.op;
    level: number;
};
export namespace HasLevel {
    export enum op {
        HAS_LEVEL = 'has_level',
    }
}

