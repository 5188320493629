/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type HasPotentialViolations = {
    op: HasPotentialViolations.op;
    potentialViolations: Array<string>;
};
export namespace HasPotentialViolations {
    export enum op {
        HAS_POTENTIAL_VIOLATIONS = 'has_potential_violations',
    }
}

