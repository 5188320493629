/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Sample = {
    op: Sample.op;
    threshold: number;
};
export namespace Sample {
    export enum op {
        SAMPLE = 'sample',
    }
}

