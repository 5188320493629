/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type HasViolations = {
    op: HasViolations.op;
    violations: Array<string>;
};
export namespace HasViolations {
    export enum op {
        HAS_VIOLATIONS = 'has_violations',
    }
}

