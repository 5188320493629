/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type HasLevelGreaterThan = {
    op: HasLevelGreaterThan.op;
    levelBound: number;
};
export namespace HasLevelGreaterThan {
    export enum op {
        HAS_LEVEL_GREATER_THAN = 'has_level_greater_than',
    }
}

