import { useState, ReactNode } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

type Props = {
  children: ReactNode;
  title: string;
  icon?: ReactNode;
  show?: boolean;
  hasDivider?: boolean;
};

const Accordion = ({
  children,
  title,
  icon = null,
  show = true,
  hasDivider = true
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  if (!show) return null;
  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between w-full"
      >
        <h5 className="flex gap-2">
          {icon}
          {title}
        </h5>
        <div>
          {isOpen && <IoIosArrowUp size={22} />}
          {!isOpen && <IoIosArrowDown size={22} />}
        </div>
      </button>
      {isOpen && children}
      {hasDivider && <div className="border-b border-border my-3" />}
    </>
  );
};

export { Accordion };
