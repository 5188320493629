/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SamplePerQueue = {
    op: SamplePerQueue.op;
    thresholds: Record<string, number>;
};
export namespace SamplePerQueue {
    export enum op {
        SAMPLE_PER_QUEUE = 'sample_per_queue',
    }
}

