import { useState, Fragment } from 'react';
import { useApiService } from '@api/services';
import { Tooltip } from '@components/shared/Tooltip/Tooltip';
import { FaMinusCircle, FaInfoCircle, FaPencilAlt } from 'react-icons/fa';
import { IoIosAddCircle, IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { PolicyRule, EvaluationsBin } from '@api/types';
import { PolicyChart } from '@components/Policy/PolicyChart';
import { LOWER_THRESHOLD, UPPER_THRESHOLD } from '../../constants';
import { Dialog } from '@components/shared/Dialog';
import { EditRuleForm } from './PolicyActions/EditRule';
import { AiFillExclamationCircle } from 'react-icons/ai';
import { MdDelete } from 'react-icons/md';
import { Button } from '@components/shared/Buttons';
import { PolicyRuleType } from '@typeDef/Policy';
import { useUser } from '@context/UserProvider';

const PolicyRuleTable = ({
  rulesList,
  onDiscard,
  onDelete,
  onEdit,
  show,
  setShow,
  ruleSelected,
  setRuleSelected
}: {
  rulesList: PolicyRuleType[];
  onDiscard: (rule: PolicyRuleType) => void;
  onDelete: (rule: PolicyRuleType) => void;
  onEdit: (rule: PolicyRuleType) => void;
  setShow: (val: boolean) => void;
  show: boolean;
  ruleSelected: PolicyRuleType | null;
  setRuleSelected: (rule: PolicyRuleType | null) => void;
}) => {
  const { user } = useUser();
  const { useEvaluationsHistogram } = useApiService();
  const [threshold, setThreshold] = useState(0.5);
  const [upperThreshold, setUpperThreshold] = useState(0.5);

  const { data: histogramData } = useEvaluationsHistogram({
    label: ruleSelected?.label
  });

  if (!rulesList) return <div>No rules found</div>;

  const counts = histogramData?.bins?.map((x: EvaluationsBin) =>
    x.score != '0.0' ? x.count : null
  );

  const header = [
    'Label',
    'Queue content threshold',
    'Automatic enforcement threshold',
    'Restricted to',
    // 'Incident',
    // 'Enforcement',
    ''
  ];

  const handleClick = (x: PolicyRule) => {
    if (x === ruleSelected) return setRuleSelected(null);
    setRuleSelected(x);
    setThreshold(x.lower_threshold);
    setUpperThreshold(x.upper_threshold);
  };

  const getTooltip = (x: string) =>
    x.includes('Queue') ? LOWER_THRESHOLD : UPPER_THRESHOLD;

  const getRuleStatus = (rule: PolicyRuleType) => {
    const { status } = rule;
    if (!status) return null;
    if (status === 'added')
      return (
        <Button
          onClick={() => onDiscard(rule)}
          hiddenTitle="discarded add Policy"
          tooltip="Click to Undo the pending change"
          tooltipStyle="ml-2"
          style="hover:bg-transparent ml-4"
        >
          <IoIosAddCircle size="28" color="#38D900" />
        </Button>
      );
    if (status === 'edited')
      return (
        <Button
          onClick={() => onDiscard(rule)}
          hiddenTitle="discarded edit policy"
          tooltip="Click to Undo the pending change"
          style="ml-4 hover:bg-transparent"
        >
          <AiFillExclamationCircle size="24" color="#FCA701" />
        </Button>
      );
    if (status === 'deleted')
      return (
        <Button
          onClick={() => onDiscard(rule)}
          hiddenTitle="discarded delete policy"
          tooltip="Click to Undo the pending change"
          style="ml-4 hover:bg-transparent"
        >
          <FaMinusCircle size="22" color="red" />
        </Button>
      );
  };

  return (
    <div className="rounded-md border border-border">
      <table className="w-full border-collapse">
        <thead className="bg-custom-bg h-[72px] border-border border-b">
          <tr>
            {header.map((x: string) => (
              <th
                className="ps-0 font-bold first:indent-5 first:rounded-tl-md last:rounded-tr-md"
                key={x}
              >
                <span className="flex gap-1">
                  <span className="flex flex-wrap max-w-44 text-left">{x}</span>
                  <div>
                    {x.includes('threshold') && (
                      <Tooltip
                        content={getTooltip(x)}
                        placement={`bottom w-[200px] mt-2 ${
                          x.includes('Low') ? 'h-[85px]' : 'h-[55px]'
                        } font-normal`}
                      >
                        <FaInfoCircle className="mb-1 w-4 h-4" />
                      </Tooltip>
                    )}
                  </div>
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.values(rulesList).map((rule: PolicyRuleType, i: number) => (
            <Fragment key={rule.uuid}>
              <tr
                onClick={() => handleClick(rule)}
                data-testid={'ruleTableRow'}
                className={`${
                  i % 2 && 'bg-fg-light'
                } border-border ${i !== rulesList.length - 1 || JSON.stringify(ruleSelected) === JSON.stringify(rule) ? 'border-b' : 'roundLast'} hover:bg-highlight h-[72px] cursor-pointer`}
              >
                <td>
                  <div className="items-center flex h-full">
                    <span className="mr-2">{getRuleStatus(rule)}</span>{' '}
                    {rule.strategyIcon?.map((x: string, i: number) => (
                      <img
                        key={`${x}-${i}`}
                        width="20"
                        alt="strategy-icon"
                        className="p-2 w-10 flex"
                        src={x}
                      />
                    ))}
                    {rule.label}
                  </div>
                </td>
                <td>{rule.lower_threshold}%</td>
                <td>{rule.upper_threshold}%</td>
                <td>
                  {rule.content_type} {rule.field}
                </td>
                <td className="p-2">
                  <div className="bg-transparent">
                    {JSON.stringify(ruleSelected) === JSON.stringify(rule) ? (
                      <IoIosArrowUp />
                    ) : (
                      <IoIosArrowDown />
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                {JSON.stringify(ruleSelected) === JSON.stringify(rule) && (
                  <td colSpan={header.length}>
                    {user?.isUserAdmin && (
                      <div className="flex gap-2 justify-end m-1 p-3">
                        <Button
                          hiddenTitle="editRule"
                          tooltip="Edit rule"
                          tooltipStyle="bottom min-h-[0px] max-h-[28px]"
                          style="iconBtnOutlined w-7 h-7 rounded-xl flex justify-center items-center"
                          onClick={() => setShow(true)}
                        >
                          <FaPencilAlt size="14" />
                        </Button>
                        <Button
                          style="text-error w-7 h-7 border-2 border-error rounded-xl flex justify-center items-center hover:bg-error hover:text-white"
                          hiddenTitle="deleteRule"
                          tooltip="Delete rule"
                          onClick={() => onDelete(rule)}
                        >
                          <MdDelete size="20" />
                        </Button>
                      </div>
                    )}
                    <div className="w-full relative" role="policyRuleChart">
                      <PolicyChart
                        counts={counts}
                        histogramData={histogramData}
                        threshold={threshold}
                        upperThreshol={upperThreshold}
                      />
                    </div>
                  </td>
                )}
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
      <Dialog show={show} close={() => setShow(false)}>
        <EditRuleForm ruleSelected={ruleSelected} onEdit={onEdit} />
      </Dialog>
    </div>
  );
};

export { PolicyRuleTable };
