/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IncidentStatus } from './IncidentStatus';
export type HasIncidentStatus = {
    op: HasIncidentStatus.op;
    status: IncidentStatus;
};
export namespace HasIncidentStatus {
    export enum op {
        HAS_INCIDENT_STATUS = 'has_incident_status',
    }
}

