/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IncidentCause } from './IncidentCause';
export type HasIncidentCauses = {
    op: HasIncidentCauses.op;
    causes: Array<IncidentCause>;
};
export namespace HasIncidentCauses {
    export enum op {
        HAS_INCIDENT_CAUSES = 'has_incident_causes',
    }
}

