/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IncidentResolution } from './IncidentResolution';
export type HasIncidentResolutions = {
    op: HasIncidentResolutions.op;
    resolutions: Array<IncidentResolution>;
};
export namespace HasIncidentResolutions {
    export enum op {
        HAS_INCIDENT_RESOLUTIONS = 'has_incident_resolutions',
    }
}

