/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type HasQualityChecks = {
    op: HasQualityChecks.op;
};
export namespace HasQualityChecks {
    export enum op {
        HAS_QUALITY_CHECKS = 'has_quality_checks',
    }
}

