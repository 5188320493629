/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type HasTags = {
    op: HasTags.op;
    tags: Array<string>;
};
export namespace HasTags {
    export enum op {
        HAS_TAGS = 'has_tags',
    }
}

