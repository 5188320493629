/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type HasNoIncidents = {
    op: HasNoIncidents.op;
};
export namespace HasNoIncidents {
    export enum op {
        HAS_NO_INCIDENTS = 'has_no_incidents',
    }
}

